:root {
  --afton: yellow;
  --vgno: red;
  --krone: #E2011B;
}

/*
#root {
  border: 20px var(--afton) solid;
  padding-bottom: 2em;
}
*/
